import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_vlazyload_e69745ec from 'nuxt_plugin_vlazyload_e69745ec' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_35f56cc4 from 'nuxt_plugin_cookieuniversalnuxt_35f56cc4' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_dayjsplugin_793c9c41 from 'nuxt_plugin_dayjsplugin_793c9c41' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_axios_1fdcf5c8 from 'nuxt_plugin_axios_1fdcf5c8' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_db7776c2 from 'nuxt_plugin_pluginutils_db7776c2' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_6f73b3ac from 'nuxt_plugin_pluginrouting_6f73b3ac' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_e0a2f4f6 from 'nuxt_plugin_pluginmain_e0a2f4f6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_deviceplugin_28d040a7 from 'nuxt_plugin_deviceplugin_28d040a7' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_i18n_bcd7274e from 'nuxt_plugin_i18n_bcd7274e' // Source: ../plugins/lib/i18n.js (mode: 'all')
import nuxt_plugin_axios_51943081 from 'nuxt_plugin_axios_51943081' // Source: ../plugins/lib/axios.js (mode: 'all')
import nuxt_plugin_persistedstate_cd97bcc0 from 'nuxt_plugin_persistedstate_cd97bcc0' // Source: ../plugins/lib/persisted-state.js (mode: 'all')
import nuxt_plugin_dayjs_38e10e9c from 'nuxt_plugin_dayjs_38e10e9c' // Source: ../plugins/lib/dayjs.js (mode: 'all')
import nuxt_plugin_primevue_1227f47f from 'nuxt_plugin_primevue_1227f47f' // Source: ../plugins/lib/prime-vue.js (mode: 'all')
import nuxt_plugin_veevalidate_60dc9aac from 'nuxt_plugin_veevalidate_60dc9aac' // Source: ../plugins/lib/vee-validate.js (mode: 'all')
import nuxt_plugin_vueportal_66bbc078 from 'nuxt_plugin_vueportal_66bbc078' // Source: ../plugins/lib/vue-portal.js (mode: 'all')
import nuxt_plugin_swiper_3223d082 from 'nuxt_plugin_swiper_3223d082' // Source: ../plugins/lib/swiper.js (mode: 'all')
import nuxt_plugin_lightboxclient_aebca95a from 'nuxt_plugin_lightboxclient_aebca95a' // Source: ../plugins/lib/lightbox.client.js (mode: 'client')
import nuxt_plugin_vuescrollmagicclient_7c74c517 from 'nuxt_plugin_vuescrollmagicclient_7c74c517' // Source: ../plugins/lib/vue-scrollmagic.client.js (mode: 'client')
import nuxt_plugin_vuepannellumclient_76144afa from 'nuxt_plugin_vuepannellumclient_76144afa' // Source: ../plugins/lib/vue-pannellum.client.js (mode: 'client')
import nuxt_plugin_globalcomponents_52ce1226 from 'nuxt_plugin_globalcomponents_52ce1226' // Source: ../plugins/global-components.js (mode: 'all')
import nuxt_plugin_init_5aefaeb1 from 'nuxt_plugin_init_5aefaeb1' // Source: ../plugins/init.js (mode: 'all')
import nuxt_plugin_router_79dc5b58 from 'nuxt_plugin_router_79dc5b58' // Source: ../plugins/router.js (mode: 'all')
import nuxt_plugin_utils_1ea7651c from 'nuxt_plugin_utils_1ea7651c' // Source: ../plugins/utils.js (mode: 'all')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_head_1635d3e1 from 'nuxt_plugin_head_1635d3e1' // Source: ../plugins/head.js (mode: 'all')
import nuxt_plugin_three_70a1b34e from 'nuxt_plugin_three_70a1b34e' // Source: ../plugins/lib/three.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Liget Budapest","meta":[{"charset":"utf-8"},{"property":"og:type","content":"website"},{"name":"format-detection","content":"telephone=no"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"},{"property":"og:site_name","content":"Liget Budapest"},{"name":"msapplication-TileColor","content":"#FAFAFA"},{"name":"theme-color","content":"#FAFAFA"},{"hid":"og:image","property":"og:image","content":"\u002Fog-image.png"},{"hid":"og:image:url","property":"og:image:url","content":"\u002Fog-image.png"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"},{"rel":"mask-icon","href":"\u002Fsafari-pinned-tab.svg","color":"#1F1F1F"}],"script":[{"src":"\u002Fscripts\u002Fbody-scroll-lock.min.js","body":true},{"src":"https:\u002F\u002Fjs.hsforms.net\u002Fforms\u002Fv2.js"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_vlazyload_e69745ec === 'function') {
    await nuxt_plugin_vlazyload_e69745ec(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_35f56cc4 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_35f56cc4(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_793c9c41 === 'function') {
    await nuxt_plugin_dayjsplugin_793c9c41(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1fdcf5c8 === 'function') {
    await nuxt_plugin_axios_1fdcf5c8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_db7776c2 === 'function') {
    await nuxt_plugin_pluginutils_db7776c2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6f73b3ac === 'function') {
    await nuxt_plugin_pluginrouting_6f73b3ac(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_e0a2f4f6 === 'function') {
    await nuxt_plugin_pluginmain_e0a2f4f6(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_28d040a7 === 'function') {
    await nuxt_plugin_deviceplugin_28d040a7(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_bcd7274e === 'function') {
    await nuxt_plugin_i18n_bcd7274e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_51943081 === 'function') {
    await nuxt_plugin_axios_51943081(app.context, inject)
  }

  if (typeof nuxt_plugin_persistedstate_cd97bcc0 === 'function') {
    await nuxt_plugin_persistedstate_cd97bcc0(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_38e10e9c === 'function') {
    await nuxt_plugin_dayjs_38e10e9c(app.context, inject)
  }

  if (typeof nuxt_plugin_primevue_1227f47f === 'function') {
    await nuxt_plugin_primevue_1227f47f(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_60dc9aac === 'function') {
    await nuxt_plugin_veevalidate_60dc9aac(app.context, inject)
  }

  if (typeof nuxt_plugin_vueportal_66bbc078 === 'function') {
    await nuxt_plugin_vueportal_66bbc078(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_3223d082 === 'function') {
    await nuxt_plugin_swiper_3223d082(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lightboxclient_aebca95a === 'function') {
    await nuxt_plugin_lightboxclient_aebca95a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollmagicclient_7c74c517 === 'function') {
    await nuxt_plugin_vuescrollmagicclient_7c74c517(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuepannellumclient_76144afa === 'function') {
    await nuxt_plugin_vuepannellumclient_76144afa(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_52ce1226 === 'function') {
    await nuxt_plugin_globalcomponents_52ce1226(app.context, inject)
  }

  if (typeof nuxt_plugin_init_5aefaeb1 === 'function') {
    await nuxt_plugin_init_5aefaeb1(app.context, inject)
  }

  if (typeof nuxt_plugin_router_79dc5b58 === 'function') {
    await nuxt_plugin_router_79dc5b58(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_1ea7651c === 'function') {
    await nuxt_plugin_utils_1ea7651c(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (typeof nuxt_plugin_head_1635d3e1 === 'function') {
    await nuxt_plugin_head_1635d3e1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_three_70a1b34e === 'function') {
    await nuxt_plugin_three_70a1b34e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
